import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  connect() {
    if (!Number.isInteger(window.countOpenModals)) window.countOpenModals = 0
    $(this.modalTarget).on('show.bs.modal', () => this.modalShow());
    $(this.modalTarget).on('hidden.bs.modal', () => this.modalHidden());
  }

  disconnect() {
    $(this.modalTarget).off('show.bs.modal');
    $(this.modalTarget).off('hidden.bs.modal');
  }

  modalShow() {
    window.countOpenModals += 1
    this.modalTarget.dispatchEvent(new Event(`${this.identifier}:show`, { "bubbles": true }))
  }

  modalHidden() {
    window.countOpenModals -= 1
    this.modalTarget.dispatchEvent(new Event(`${this.identifier}:hidden`, { "bubbles": true }))
  }

  close() {
    $(this.modalTarget).modal('hide')
  }

  open() {
    $(this.modalTarget).modal({
      show: true,
      focus: true
    })
    // move backdrop after modalTarget
    this.modalTarget.after(document.querySelector(".modal-backdrop"));
  }
}
