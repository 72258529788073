import { Controller } from "@hotwired/stimulus"

// example:
// <div data-controller="display-toggle">
//   <input data-display-toggle-target="source" data-action="display-toggle#toggle" type="checkbox" name="copy_record[outside]">
//     <div data-display-toggle-target="target">
//       <label for="copy_record_outside_library_card_type_ids">Typy kart czytelnika</label>
//       <select name="copy_record[outside_library_card_type_ids][]" id="copy_record_outside_library_card_type_ids">
//         <option selected="selected" value="902678660">pracownik naukowy</option>
//         <option value="925085493">student</option>
//       </select>
//     </div>
// </div>
export default class extends Controller {
  static targets = [
    "source",
    "target"
  ]

  connect() {
    this.toggle()
  }

  toggle(_) {
    const checked = this.sourceTargets.reduce((acc, el) => {
      if (acc) return true;
      return el.checked == true;
    }, false);
    this.targetTargets.forEach(target => {
      target.classList.toggle('d-none', !checked)
    })
  }
}
