import WithSelectController from "./with_select_controller";

export default class extends WithSelectController {
  static targets = [
    "itemsGroup", // items group container
  ]

  addItem(value) {
    const template = this.templateTargets.find((template) => template.dataset.itemValue === value);
    const content = template.innerHTML.replace(
      new RegExp(this.itemId(), 'g'),
      new Date().valueOf()
    );
    const group = this.itemsGroupTargets.find(e => { return e.dataset.itemValue == value })
    group.insertAdjacentHTML('beforeend', content)
    this.afterAddItem(Array.from(group.childNodes).pop())
    return Array.from(group.childNodes).pop()
  }
}
