import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    focusSelectorAfterDuplicate: String
  }

  duplicate(event) {
    const newNode = this.element.cloneNode(true);
    let afterDuplicateEvent = new Event('duplicate:after-duplicate', { "bubbles": true })

    if (this.data.has('replaceNamePrefix'))
      afterDuplicateEvent.detail = this.replaceNamePrefix(newNode);
    this.removeIdNodes(newNode);
    this.removeSelect2(newNode);
    this.element.after(newNode);

    if (this.hasFocusSelectorAfterDuplicateValue) {
      newNode.querySelector(this.focusSelectorAfterDuplicateValue)?.focus()
    }

    newNode.dispatchEvent(afterDuplicateEvent)
  }

  replaceNamePrefix(newNode) {
    const newId = new Date().valueOf();
    const oldNamePrefix = this.data.get('replaceNamePrefix');
    const newNamePrefix = oldNamePrefix.replace(/\[\d+\]$/, `[${newId}]`);
    const oldIdPrefix = oldNamePrefix.replace(/[\[\]]+/g, '_').replace(/_$/, '');
    const newIdPrefix = newNamePrefix.replace(/[\[\]]+/g, '_').replace(/_$/, '');
    // update name and id attributes
    const replacePrefix = (node) => {
      node.name = node.name.replace(oldNamePrefix, newNamePrefix)
      node.id = node.id.replace(oldIdPrefix, newIdPrefix)
    }
    newNode.querySelectorAll(`[name^="${oldNamePrefix}"]`).forEach(replacePrefix)
    // update all templates in newNode
    newNode.querySelectorAll(`template`).forEach( (node) => {
      node.content.querySelectorAll(`[name^="${oldNamePrefix}"]`).forEach(replacePrefix)
    })
    // update data-duplicate-replace-name-prefix attribute
    const replaceDuplicateReplaceNamePrefix = (node) => {
      const oldPrefix = node.dataset.duplicateReplaceNamePrefix
      node.dataset.duplicateReplaceNamePrefix = oldPrefix.replace(oldNamePrefix, newNamePrefix)
    }
    newNode.dataset.duplicateReplaceNamePrefix = newNamePrefix
    newNode.querySelectorAll(`[data-duplicate-replace-name-prefix^="${oldNamePrefix}"]`).forEach(replaceDuplicateReplaceNamePrefix)
    newNode.querySelectorAll(`template`).forEach( (node) => {
      node.content.querySelectorAll(`[data-duplicate-replace-name-prefix^="${oldNamePrefix}"]`).forEach(replaceDuplicateReplaceNamePrefix)
    })
    return { oldNamePrefix, newNamePrefix, oldIdPrefix, newIdPrefix }
  }

  removeIdNodes(newNode) {
    newNode.querySelectorAll('[name$="[id]"]').forEach((node) => {
      node.remove();
    });
  }

  removeSelect2(newNode) {
    newNode.querySelectorAll('.select2').forEach(node => node.remove());
    newNode.querySelectorAll('[data-select2-id]').forEach((node) => {
      node.removeAttribute('data-select2-id');
    })
  }

}
