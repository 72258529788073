import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'value',
    'multiplier',
    'total'
  ]

  changeValue (e) {
    this.valueTarget.value = e.target.options[e.target.options.selectedIndex].dataset.value
    this.updateTotal()
  }

  updateTotal() {
    this.totalTarget.value =
      parseInt(this.multiplierTarget.value)
        * parseFloat(this.valueTarget.value)
  }
}
