import { Controller } from "@hotwired/stimulus"
import LocalSettings from "../lib/local_settings"

export default class extends Controller {
  static targets = ["addField", "addSubfield", "toggle"]
  connect() {
    this.element[this.identifier] = this
    this.toggle(LocalSettings.getValue("hotkeysEnabled"))
  }

  keyPress(e) {
    if (!LocalSettings.getValue("hotkeysEnabled") || window.countOpenModals > 0) return;

    if (e.target.matches('input')) {
      if (e.key === 'Escape') {
        this.data.set('lastFocusedInputId', e.target.id)
        e.target.blur()
      } else {
        return;
      }
    } else if (e.key === '=') {
      this.addSubfield();
    } else if (e.key === '+') {
      this.addField();
    }
  }

  addField() {
    this.openSelect2(this.addFieldTarget);
  }

  addSubfield() {
    const fieldSelector = `[data-${this.identifier}-target="field"]`;
    const activeField = document.activeElement.closest(fieldSelector)
    if (activeField) {
      const addSubfieldTarget = this.addSubfieldTargets.find((addSubfieldTarget) => {
        return addSubfieldTarget.closest(fieldSelector) === activeField;
      })
      this.openSelect2(addSubfieldTarget);
    } else if (this.data.has('lastFocusedInputId')) {
      const lastFocusedInputId = this.data.get('lastFocusedInputId');
      const addSubfieldTarget = this.addSubfieldTargets.find((addSubfieldTarget) => {
        return addSubfieldTarget.closest('fieldset').querySelector(`[id="${lastFocusedInputId}"]`)
      })
      this.openSelect2(addSubfieldTarget);
    } else {
      this.openSelect2(this.addSubfieldTargets.pop());
    }
  }

  openSelect2(node) {
    $(node).select2('open');
  }

  toggle(force) {
    const bool = (typeof force === "boolean") ? force : !LocalSettings.getValue("hotkeysEnabled")
    if (this.hasToggleTarget) this.toggleTarget.checked = bool
    LocalSettings.setValue("hotkeysEnabled", bool)
  }
}